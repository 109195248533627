import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";

import { theme } from "../common/theme";
import { ResetCSS } from "../assets/css/style";
import { ContentWrapper, GlobalStyle } from "../assets/css/saas.style";
import Navbar from "../containers/common/Navbar";
import Footer from "../containers/common/Footer";
import { DrawerProvider } from "../contexts/DrawerContext";
import SEO from "../components/SEO";
import JobContentView from "../containers/jobs/JobContentView";

const jobTemplate = ({ data, pageContext }) => {
  const title = pageContext.location ? `${pageContext.title} in ${pageContext.location}` : pageContext.title;
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <SEO
          title={title}
          url={pageContext.slug}
          type="article"
          keywords={pageContext.keywords}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar notMainPage />
            </DrawerProvider>
          </Sticky>
          <JobContentView
            siteUrl="https://jobs.talentswipe.careers" // Should not be hardcoded but has a bug sometimes
            data={pageContext}
          />
          <Footer notMainPage />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

jobTemplate.propTypes = {
  pageContext: PropTypes.object
};

export default jobTemplate;
